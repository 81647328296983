<template>
    <div>
        <form @submit.prevent="submitForm">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="name" required>Scenario Name</label>
                            <input type="text" class="form-control" v-model="formData.name" required>
                            <div v-if="validationErrors &&  validationErrors.name" class="text-danger">{{ validationErrors.name[0] }}</div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="tracking_id">Tracking ID</label>
                            <input type="text" class="form-control" v-model="formData.tracking_id">
                            <div v-if="validationErrors &&  validationErrors.tracking_id" class="text-danger">{{ validationErrors.tracking_id[0] }}</div>

                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label for="description">Description</label>
                    <textarea class="form-control" v-model="formData.description" rows="3"></textarea>
                    <div v-if="validationErrors &&  validationErrors.description" class="text-danger">{{ validationErrors.description[0] }}</div>

                </div>

                <div id="company" class="form-group" v-if="can_toggle_company">

                    <label for="company">Company</label>
                    <v-select v-model="formData.company_slug"
                              @update:modelValue="loadSubscribercodes"
                              :options="companies"
                              label="name"
                              :reduce="company => company.slug"
                              placeholder="No Company (System Scenario)"
                              :clearable="false"
                              required>
                    </v-select>

                    <div v-if="errors && errors.company_slug" class="text-danger">{{ errors.company_slug[0] }}</div>
                </div>
                <div id="subscribercode" class="form-group" v-if="can_toggle_company">
                    <label for="subscriber_code" required>Subscriber Code Set</label>
                    <select class="form-select" id="subscriber_code" name="subscriber_code" v-model="formData.subscriber_code" required>
                        <option value="" disabled>Select a Subscriber Code</option>
                        <option value="default" :selected="scenario.subscriber_code === ''">Default</option>
                        <option v-for="subscribercode in subscribercodes" :key="subscribercode.slug" :value="subscribercode.slug" :selected="subscribercode.slug === scenario.subscriber_code">
                            {{ subscribercode.name }}
                        </option>
                    </select>
                    <div v-if="errors && errors.subscriber_code" class="text-danger">{{ errors.subscriber_code[0] }}</div>
                </div>


                <div class="form-group" v-if="!can_toggle_company">
                    <label required>Subscriber Code Set</label>
                    <select class="form-select" v-model="formData.subscriber_code" required>
                        <option v-for="(subscriber, index) in subscribers" :key="subscriber.slug" :value="subscriber.slug">{{ subscriber.name }}</option>
                    </select>
                    <div v-if="validationErrors &&  validationErrors.subscriber_code" class="text-danger">{{ validationErrors.subscriber_code[0] }}</div>

                </div>

                <div class="form-group">
                    <label required>Credit Reports</label>
                    <div v-if="validationErrors && (validationErrors.experian || validationErrors.transunion || validationErrors.equifax )" class="text-danger">A credit bureau must be selected.</div>

                    <div v-for="(bureau, index) in bureaus" :key="index" class="mb-3 card">
                        <div class="card-body">
                            <label class="form-check-label pointer" :for="bureau.id">
                                <div class="form-check fw-bold">
                                    <input type="checkbox" class="form-check-input" :id="bureau.id" :value="bureau.value" v-model="formData.selectedBureaus">
                                    {{ bureau.label }}
                                </div>
                            </label>
                            <div class="pt-3" v-if="formData.selectedBureaus.includes(bureau.value)">
                                <label>{{ bureau.label }} Score Models</label>
                                <div v-if="validationErrors && validationErrors.creditScores" class="text-danger">{{ validationErrors.creditScores[0] }}</div>

                                <div v-for="(score, scoreIndex) in formData.creditScores[bureau.value]" :key="scoreIndex" class="d-flex align-items-center mb-1">
                                    <select class="form-control me-2" v-model="formData.creditScores[bureau.value][scoreIndex]">
                                        <template v-for="scoreOption in creditScoreOptions">
                                            <option :key="scoreOption.slug" :value="scoreOption.slug" v-if="scoreOption.bureau === bureau.value">
                                                {{ scoreOption.model_name }}
                                            </option>
                                        </template>
                                    </select>

                                    <button type="button" @click="removeCreditScore(bureau.value, scoreIndex)" class="ml-2 btn btn-sm btn-danger">Delete</button>
                                </div>
                                <div>
                                    <button type="button" @click="addCreditScore(bureau.value)" class="btn btn-sm btn-secondary mt-2">Add Score</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label>Decisioning</label>

                    <div v-if="formData.criteriaSets.length > 0">
                        <div v-if="validationErrors && validationErrors.criteriaSets" class="text-danger">{{ validationErrors.criteriaSets[0] }}</div>
                        <div v-for="(criteriaSet, index) in formData.criteriaSets" :key="index" class="mb-3 card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col">
                                        <label>Criteria Set {{ index + 1 }}</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">

                                        <select class="form-select" v-model="criteriaSet.selectedCriteria">
                                            <option v-for="criteria in criteriaSetOptions" :key="criteria.slug" :value="criteria.slug">{{ criteria.name }}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="ms-5">
                                            <!-- Loop through offers and provide a select box for each -->
                                            <div v-for="(selectedOffer, offerIndex) in criteriaSet.offers" :key="offerIndex" class="mt-2">
                                                <label>Offer {{ offerIndex + 1 }}</label>
                                                <div class="row">
                                                    <div class="col-8">
                                                        <select class="form-select" v-model="criteriaSet.offers[offerIndex]">
                                                            <option v-for="offer in offers" :key="offer.slug" :value="offer.slug">{{ offer.name }}</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-4">
                                                        <!-- Delete button to remove the selected offer -->
                                                        <button type="button" @click="removeOfferFromCriteriaSet(criteriaSet, offerIndex)" class="btn btn-sm btn-danger">Remove Offer</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="row pt-3">
                                    <div class="col">
                                        <button type="button" @click="removeCriteriaSet(index)" class="btn btn-sm btn-danger">Remove Criteria Set</button>
                                        <button type="button" @click="addNewOfferSelection(criteriaSet)" class="btn btn-sm btn-secondary">Add Offer</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-2">
                        <button type="button" @click="addCriteriaSet" class="btn btn-sm btn-secondary">Add Criteria Set</button>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <button type="submit" class="btn btn-primary">Save Scenario</button>
            </div>
        </form>
    </div>
</template>

<script>
import vSelect from "vue-select";

export default {
    components: {vSelect},
    data() {
        return {
            formData: {
                name: '',
                description: '',
                tracking_id: '',
                subscriber_code: '',
                selectedBureaus: [],
                creditScores: {},
                criteriaSets: [],
                company_slug: '',
            },

            subscribercodes: {},
            errors: {},
            fields: {},

            subscribers: [/* Your subscriber options here */],

            bureaus: [
                { id: 'experian', value: 'experian', label: 'Experian' },
                { id: 'transunion', value: 'transunion', label: 'TransUnion' },
                { id: 'equifax', value: 'equifax', label: 'Equifax' }
            ],

            creditScoreOptions: [
            ],

            offers: [
            ],

            criteriaSetOptions: [],

            validationErrors: null,

        };
    },
    props: [
        'companies',
        'can_toggle_company',
        'scenario',
    ],
    mounted() {
        this.fetchCreditScores();
        this.fetchOffers();
        this.fetchCriteriaSets();
        this.fetchSubscriberCodes()

        this.fields = this.scenario;
        if(this.formData.company_slug) {
            this.loadSubscribercodes();
        }
    },
    created() {
        if (!this.can_toggle_company) {
            // This is either a subscribercode manager or company manager there is only one company
            this.formData.company_slug = this.companies[0].slug;
            console.log(this.formData.company_slug);
        }
        let params = new URLSearchParams(window.location.search);
        this.urlParams = { 'c': params.get('c'), 'b': params.get('b')};
        if (this.urlParams.c) {
            this.formData.company_slug = this.urlParams.c;
        }
    },
    methods: {
        async submitForm() {

            try {
                const response = await axios.post('/scenarios', this.formData);
                // handle the response, maybe redirect or show a success message
                window.location = '/scenarios?success=created'
            } catch (error) {
                console.error('Error submitting form:', error);
                if (error.response) {
                    console.error('Response data:', error.response.data);
                }

                // handle the error, maybe show an error message to the user
                if (error.response && error.response.data && error.response.data.errors) {
                    // If the server returned validation errors
                    this.validationErrors = error.response.data.errors;
                    // Handle the validation errors, you might show them in the form
                    console.log('Validation errors:', validationErrors);
                } else {
                    // If it's a general error, you can show a generic error message
                    // Or take other appropriate action
                    console.error('An error occurred while submitting the form.');
                }
            }
        },
        addCreditScore(bureau) {
            if (!this.formData.creditScores[bureau]) {
                this.formData.creditScores[bureau] = [];
            }
            this.formData.creditScores[bureau].push('');
        },
        addCriteriaSet() {
            this.formData.criteriaSets.push({ offers: [] });
        },
        getCriteriaName(slug) {
            const criteria = this.criteriaSets.find(c => c.slug === slug);
            return criteria ? criteria.name : '';
        },
        getOffersForCriteria(slug) {
            // Logic to fetch offers for the selected criteria based on its slug
            // This is a placeholder and should be modified based on your actual data structure and logic
            return this.offers.filter(offer => offer.criteriaSlug === slug);
        },
        addOfferToCriteriaSet(criteriaSet) {
            console.log('criteriaSet Button clicked!', criteriaSet.selectedOffer);

            if (criteriaSet.selectedOffer) {
                console.log('criteriaSet Button clicked2!', criteriaSet.selectedOffer);

                criteriaSet.offers.push(criteriaSet.selectedOffer);
                criteriaSet.selectedOffer = null; // Reset the selected offer
            }
        },
        removeCreditScore(bureau, scoreIndex) {
            this.formData.creditScores[bureau].splice(scoreIndex, 1);
            if (this.formData.creditScores[bureau].length === 0) {
                this.$delete(this.formData.creditScores, bureau);
            }
        },
        removeCriteriaSet(index) {
            this.formData.criteriaSets.splice(index, 1);
        },
        removeOfferFromCriteriaSet(criteriaSet, offerIndex) {
            criteriaSet.offers.splice(offerIndex, 1);
        },
        addNewOfferSelection(criteriaSet) {
            criteriaSet.offers.push(null); // Push a null value indicating an empty offer selection
        },
        async fetchCreditScores() {
            try {
                const response = await axios.get('/scenarios/credit-scores'); // Adjust the URL if needed
                this.creditScoreOptions = response.data;
            } catch (error) {
                console.error('Error fetching CreditScores:', error);
            }
        },
        async fetchOffers() {
            try {
                const response = await axios.get('/scenarios/offers'); // Adjust the URL if needed
                this.offers = response.data;
            } catch (error) {
                console.error('Error fetching Offers:', error);
            }
        },
        async fetchCriteriaSets() {
            try {
                const response = await axios.get('/scenarios/criteria-sets'); // Adjust the URL if needed
                this.criteriaSetOptions = response.data;
            } catch (error) {
                console.error('Error fetching criteriaSets:', error);
            }
        },
        async fetchSubscriberCodes() {
            try {
                const response = await axios.get('/scenarios/subscriber-codes'); // Adjust the URL if needed
                this.subscribers = response.data;
            } catch (error) {
                console.error('Error fetching subscribers:', error);
            }
        },
        loadSubscribercodes() {
            if(this.formData.company_slug) {
                axios.get('/get-subscribercodes-by-company/' + this.formData.company_slug)
                    .then(response => {
                        this.subscribercodes = response.data;
                        // if (this.urlParams.c == this.fields.company_slug && this.urlParams.b) {
                        //     this.fields.subscribercode_slug = this.urlParams.b;
                        // }
                    }).catch(error => {
                    // console.log(error);
                });
            }else{
                this.subscribercodes = {};
            }
        },
    }
};
</script>
