<template>
<div class="page-break">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="only-print mt-4"><h4>Inquiries</h4></div>

  <template v-if="recordCount !== 0">

      <div class="table-responsive-sm">
          <table id="inquiries_table" ref="inquiries_table" class="table table-striped border text-nowrap">
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Business Type</th>
                <th>Loan Type</th>
                <th>Repository</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in normalizedData" :key="item.CreditFileID">
                <td>{{ item.Date }}</td>
                <td>{{ item.Name }}</td>
                <td>{{ addSpacesToCamelCase(item.CreditBusinessType) }}</td>
                <td>{{ item.CreditLoanType }}
                  <!--                            {{ item.CreditLoanTypeOtherDescription }}-->
                </td>
                <td>{{ item.CREDIT_REPOSITORY.SourceType }} {{ item.CREDIT_REPOSITORY.SubscriberCode }}</td>
              </tr>
            </tbody>
          </table>
      </div>


</template>
<template v-else>
<div>No Inquiries found.</div>
</template>
            </div>
        </div>
    </div>
</div>
</template>
<script setup>
import {
  addSpacesToCamelCase,summaryTabCount
} from '../../composables.js'
</script>
<script>
export default {
  props: [
    'report'
  ],
  data() {
    return {
      data: this.report?.CREDIT_INQUIRY,
      recordCount: 0
    };
  },
  mounted() {
      this.initializeDataTable();

      const vm = this;

      if (window.matchMedia) {
          window.matchMedia('print').addListener(function(media) {
              if (media.matches) { // Before printing
                  vm.destroyDataTable();
              }else{ // After printing
                  vm.initializeDataTable();
              }
          });
      }
  },
    methods: {
        initializeDataTable() {
            this.dataTable = $(this.$refs.inquiries_table).DataTable({
                pageLength: 25
            });
        },
        destroyDataTable() {
            if (this.dataTable) {
                this.dataTable.destroy();
                this.dataTable = null;
            }
        },
    },
      computed: {
        normalizedData() {
          // Check if this.data is an array, and if not, convert the single object to an array
          return Array.isArray(this.data) ? this.data : [this.data];
        },
      },
    created() {
      this.recordCount = summaryTabCount(this.data);
    },
    beforeDestroy() {
        this.destroyDataTable();
    },
};
</script>
