<template>
    <div class="swagger" id="swagger"></div>
</template>

<script>
import SwaggerUI from 'swagger-ui';
import 'swagger-ui/dist/swagger-ui.css';
import axios from 'axios';

export default {
    data() {
        return {
            permissionMappings: {}
        };
    },
    mounted() {
        this.loadPermissionMappings();
    },
    methods: {
        loadPermissionMappings() {
            try {
                this.permissionMappings = require('../swagger-configs/swagger-permissions.json'); // Adjust the path as necessary
            } catch (error) {
                console.warn('Permission mappings file not found. All operations will be shown.');
            } finally {
                this.initializeSwaggerUI();
                this.checkPermissions();
            }
        },
        initializeSwaggerUI() {
            const spec = require('../swagger-configs/openapi.json');
            SwaggerUI({
                spec: spec,
                dom_id: '#swagger',
                tryItOutEnabled: true,
            });
        },
        checkPermissions() {
            axios.get('/user-data')
                .then(response => {
                    const userPermissions = response.data.permissions;
                    this.hideUnauthorizedSections(userPermissions);
                })
                .catch(error => {
                    console.error('Failed to fetch permissions:', error);
                });
        },
        hideUnauthorizedSections(userPermissions) {

            for (const [dataTag, requiredPermission] of Object.entries(this.permissionMappings)) {
                console.log(dataTag, requiredPermission);
                if (!userPermissions.includes(requiredPermission)) {
                    const element = document.querySelector(`[data-tag="${dataTag}"]`);
                    if (element) {
                        element.closest('.opblock-tag-section').style.display = 'none';
                    }
                }
            }
        }
    }
}
</script>
