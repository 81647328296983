<template>
    <div class="card-body">
        <div class="form-group">
            <label for="name">Name</label>
            <p>{{ scenarioData.name }}</p>
        </div>
        <div class="form-group">
            <label for="description">Description</label>
            <p>{{ scenarioData.description }}</p>
        </div>
        <div class="form-group">
            <label for="tracking_id">Tracking ID</label>
            <p>{{ scenarioData.tracking_id }}</p>
        </div>
        <div class="form-group">
            <label>Subscriber Code</label>
            <p>{{ scenarioData.subscriber_code }}</p>
        </div>
        <div class="form-group">
            <label>Bureaus</label>
            <ul>
                <li v-for="(bureau, index) in selectedBureaus" :key="index">
                    {{ convertTransUnion(bureau) }} - {{ creditScores[bureau][0] }}
                </li>
            </ul>
        </div>
        <div class="form-group">
            <label>Criteria Sets</label>
            <ul>
                <li v-for="(criteriaSet, index) in criteriaSets" :key="index">
                    <div>
                        <strong>Criteria:</strong> {{ criteriaSet.selectedCriteria }}
                    </div>
                    <div>
                        <strong>Offers:</strong>
                        <ul>
                            <li v-for="(selectedOffer, offerIndex) in criteriaSet.offers" :key="offerIndex">
                                {{ selectedOffer }}
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
import { convertTransUnion } from '../composables.js';
</script>

<script>
import axios from "axios";

export default {
    props: {
        scenarioData: {
            type: Object,
            required: true,
        },
        creditScores: {
            type: Array,
            required: true,
        },
        criteriaSets: {
            required: true,
        },
        selectedBureaus: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
        };
    },
    methods: {
        getCriteriaName(slug) {
            const criteria = this.criteriaSets.find(c => c.slug === slug);
            return criteria ? criteria.name : '';
        },
        getOffersForCriteria(slug) {
            // Logic to fetch offers for the selected criteria based on its slug
            // This is a placeholder and should be modified based on your actual data structure and logic
            return this.offers.filter(offer => offer.criteriaSlug === slug);
        },
        addOfferToCriteriaSet(criteriaSet) {
            console.log('criteriaSet Button clicked!', criteriaSet.selectedOffer);

            if (criteriaSet.selectedOffer) {
                console.log('criteriaSet Button clicked2!', criteriaSet.selectedOffer);

                criteriaSet.offers.push(criteriaSet.selectedOffer);
                criteriaSet.selectedOffer = null; // Reset the selected offer
            }
        },
        removeCreditScore(bureau, scoreIndex) {
            this.formData.creditScores[bureau].splice(scoreIndex, 1);
            if (this.formData.creditScores[bureau].length === 0) {
                this.$delete(this.formData.creditScores, bureau);
            }
        },
        removeCriteriaSet(index) {
            this.formData.criteriaSets.splice(index, 1);
        },
        removeOfferFromCriteriaSet(criteriaSet, offerIndex) {
            criteriaSet.offers.splice(offerIndex, 1);
        },
        addNewOfferSelection(criteriaSet) {
            criteriaSet.offers.push(null); // Push a null value indicating an empty offer selection
        },
        async fetchCreditScores() {
            try {
                const response = await axios.get('/scenarios/credit-scores'); // Adjust the URL if needed
                this.creditScoreOptions = response.data;
                this.debug();
            } catch (error) {
                console.error('Error fetching CreditScores:', error);
            }
        },
        async fetchOffers() {
            try {
                const response = await axios.get('/scenarios/offers'); // Adjust the URL if needed
                this.offers = response.data;
            } catch (error) {
                console.error('Error fetching Offers:', error);
            }
        },
        async fetchCriteriaSets() {
            try {
                const response = await axios.get('/scenarios/criteria-sets'); // Adjust the URL if needed
                this.criteriaSetOptions = response.data;
            } catch (error) {
                console.error('Error fetching criteriaSets:', error);
            }
        },
        async fetchSubscriberCodes() {
            try {
                const response = await axios.get('/scenarios/subscriber-codes'); // Adjust the URL if needed
                this.subscribers = response.data;
            } catch (error) {
                console.error('Error fetching subscribers:', error);
            }
        }
    },
    mounted() {
        // Fetch CreditScores and Offers when the component is mounted
        this.fetchCreditScores();
        this.fetchOffers();
        this.fetchCriteriaSets();
        this.fetchSubscriberCodes();
    },
};
</script>
