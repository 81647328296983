<template>
    <div class="row">
        <div class="col-12">
            <div class="card card-primary card-tabs mt-3">
                <div class="card-header p-0 pt-1 dont-print">
                    <ul class="nav nav-tabs" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" data-bs-toggle="pill" href="#summary" role="tab" aria-controls="custom-tabs-one-summary" aria-selected="true">
                                Summary
                            </a>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link" data-bs-toggle="pill" href="#tradelines" role="tab" aria-controls="custom-tabs-one-tradelines" aria-selected="false">
                                Tradelines
                                <span class="badge bg-light text-dark rounded-pill">{{tradeLines_count}}</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-bs-toggle="pill" href="#publicrecords" role="tab" aria-controls="custom-tabs-one-publicrecords" aria-selected="false">
                                Public Records
                                <span class="badge bg-light text-dark rounded-pill">{{publicRecords_count}}</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-bs-toggle="pill" href="#inquiries" role="tab" aria-controls="custom-tabs-one-inquiries" aria-selected="false">
                                Inquiries
                                <span class="badge bg-light text-dark rounded-pill">{{inquires_count}}</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-bs-toggle="pill" href="#attributes" role="tab" aria-controls="custom-tabs-one-attributes" aria-selected="false">
                                Attributes
                            </a>
                        </li>

                        <li v-if="user && user.roles && user.roles.some(role => role.name === 'Super Admin')" class="nav-item float-start ms-auto">
                            <a class="nav-link" data-bs-toggle="pill" href="#debug" role="tab" aria-controls="custom-tabs-one-debug" aria-selected="false">
                                Debug
                            </a>
                        </li>
                        <li class="nav-item float-end" :class="{ 'ms-auto': user.roles.some(role => role.name !== 'Super Admin') }">
                            <a class="nav-link pointer" onclick="window.print();return false;">
                                Print Report
                            </a>
                        </li>

                    </ul>
                </div>

                <div class="card-body">
                    <div id="custom-tabs-one-tabContent" class="tab-content">
                        <div class="tab-pane fade active show always-print" id="summary">
                            <summary-report-component :report="report" :bureau="bureau" :decisioning=decisioning></summary-report-component>
                        </div>
                        <div class="tab-pane fade always-print" id="tradelines">
                            <trade-lines-component :report=report />
                        </div>
                        <div class="tab-pane fade always-print" id="publicrecords">
                            <public-records-component :report=report />
                        </div>
                        <div class="tab-pane fade always-print" id="inquiries">
                            <inquiries-component :report=report />
                        </div>
                        <div class="tab-pane fade" id="attributes">
                            <attributes-component :report=report />
                        </div>
                        <div v-if="user && user.roles && user.roles.some(role => role.name === 'Super Admin')" class="tab-pane fade" id="debug">
                            <debug-component :report=report :user=user :debug=debug />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { summaryTabCount } from '../composables.js'
</script>

<script>
export default {
    props: [
        'report',
        'bureau',
        'decisioning',
        'user', // user is unsecure, so don't use it to display anything that the report viewer shouldnt be able to see
        'debug' // don't reveal anything that we don't want users to see
    ],
    data() {
        return {
            showFullSSN: false
        };
    },
    computed: {
        inquires_count() {
            return summaryTabCount(this.report.CREDIT_INQUIRY);
        },
        publicRecords_count() {
            return summaryTabCount(this.report.CREDIT_PUBLIC_RECORD);
        },
        tradeLines_count() {
            return summaryTabCount(this.report.CREDIT_LIABILITY);
        },
        attributes_count() {
            return summaryTabCount(this.report.CREDIT_SUMMARY['DATA_SET']);
        },
    },
};
</script>
