<template>
    <div class="container-fluid dont-print">
        <div class="row">
            <div class="col-12">

                <div v-if="user && user.roles && user.roles.some(role => role.name === 'Super Admin')" class="card card-warning card-outline">
                    <div class="card-header">
                        <h3 class="card-title">Report Info</h3>
                    </div>
                    <div class="card-body">
                        <dl>
                            <dt>Report ID</dt>
                            <dd>{{ debug.id }}</dd>
                        </dl>
                    </div>
                </div>

                <div v-if="debug.request_payload !== null && debug.request_payload !== ''" class="card">
                    <div class="card-header pointer" data-bs-toggle="collapse" data-bs-target="#requestPayloadContent">
                        <h3 class="card-title">Request Payload</h3>
                        <div class="card-tools my-0">
                            <button type="button" class="btn bg-info btn-sm py-0" data-bs-toggle="collapse" data-bs-target="#requestPayloadContent">
                                <i class="fas fa-plus"></i>
                            </button>
                        </div>
                    </div>
                    <div id="requestPayloadContent" class="collapse">
                        <div class="card-body bg-light">
                            <pre class="prettyprint" style="border:0;">{{ debug.request_payload }}</pre>
                        </div>
                    </div>
                </div>

                <div v-if="debug.report_raw !== null && debug.report_raw !== ''" class="card">
                    <div class="card-header pointer" data-bs-toggle="collapse" data-bs-target="#rawResponseContent">
                        <h3 class="card-title">Raw Response</h3>
                        <div class="card-tools my-0">
                            <button type="button" class="btn bg-info btn-sm py-0" data-bs-toggle="collapse" data-bs-target="#rawResponseContent">
                                <i class="fas fa-plus"></i>
                            </button>
                        </div>
                    </div>
                    <div id="rawResponseContent" class="collapse">
                        <div class="card-body bg-light">
                            <pre class="prettyprint" style="border:0;">{{ debug.report_raw }}</pre>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header pointer" data-bs-toggle="collapse" data-bs-target="#reportNormalizedContent">
                        <h3 class="card-title">Report Normalized (XML)</h3>
                        <div class="card-tools my-0">
                            <button type="button" class="btn bg-info btn-sm py-0" data-bs-toggle="collapse" data-bs-target="#reportNormalizedContent">
                                <i class="fas fa-plus"></i>
                            </button>
                        </div>
                    </div>
                    <div id="reportNormalizedContent" class="collapse">
                        <div class="card-body bg-light">
                            <pre class="prettyprint" style="border:0;">{{ debug.report_normalized }}</pre>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header pointer" data-bs-toggle="collapse" data-bs-target="#reportNormalizedCleanedContent">
                        <h3 class="card-title">Report Normalized &amp; Cleaned (JSON)</h3>
                        <div class="card-tools my-0">
                            <button type="button" class="btn bg-info btn-sm py-0" data-bs-toggle="collapse" data-bs-target="#reportNormalizedCleanedContent">
                                <i class="fas fa-plus"></i>
                            </button>
                        </div>
                    </div>
                    <div id="reportNormalizedCleanedContent" class="collapse">
                        <div class="card-body bg-light">
                            <pre class="prettyprint" style="border:0;">{{ report }}</pre>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: [
        'report',
        'user', // user is unsecure, so don't use it to display anything that the report viewer shouldnt be able to see
        'debug'
    ]
};
</script>
