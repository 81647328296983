<template>
  <div>
      <div class="container-fluid page-break">
          <div class="row">
              <div class="col-12">
                  <div class="only-print mt-4"><h4>Tradelines</h4></div>

    <template v-if="recordCount !== 0">

              <div class="table-responsive">
            <table ref="tradeLines_table" class="table table-striped border text-nowrap">
              <thead>
                <tr>
                    <th class="no-sort dont-print"></th>
                    <th class="table-danger" title="Derogatory Data Indicator">D</th>
                    <th>Status</th>
                    <th>Creditor</th>
                    <th>Account Type</th>
                    <th>Balance</th>
                    <th>Terms</th>
                    <th>ECOA</th>
                    <th>Source</th>
                </tr>
              </thead>
              <tbody>
                <tr class="pointer" v-for="item in normalizedData"
                    :key="item.id"
                    :class="{'table-danger': item?.DerogatoryDataIndicator === 'Y'}"
                    :data-account-identifier="item?.AccountIdentifier"
                    :data-loan-type="addSpacesToCamelCase(item?.CreditLoanType)"
                    :data-date-reported="item?.AccountReportedDate"
                    :data-date-opened="item?.AccountOpenedDate"
                    :data-last-activity-date="item?.LastActivityDate"
                    :data-credit-limit="item?.CreditLimitAmount ? '$'+Number(item?.CreditLimitAmount).toLocaleString() : '-'"
                    :data-high-credit="`$${Number(item?.HighCreditAmount).toLocaleString()}`"
                    :data-high-balance="item?.HighBalanceAmount ? '$'+Number(item?.HighBalanceAmount).toLocaleString() : '-'"
                    :data-past-due="item?.PastDueAmount ? '$'+Number(item?.PastDueAmount).toLocaleString() : '-'"
                    :data-business-type="addSpacesToCamelCase(item?.CreditBusinessType)"
                    :data-30-day-derogs="item?.LATE_COUNT?.['30Days'] ? item.LATE_COUNT['30Days'] : '0'"
                    :data-60-day-derogs="item?.LATE_COUNT?.['60Days'] ? item.LATE_COUNT['60Days'] : '0'"
                    :data-90-day-derogs="item?.LATE_COUNT?.['90Days'] ? item.LATE_COUNT['90Days'] : '0'"
                    :data-credit-comment="tradeLinesCreditComments(item?.CREDIT_COMMENT)"
                    :data-payment-pattern="item?.PAYMENT_PATTERN?.StartDate ? item?.PAYMENT_PATTERN?.StartDate + ', ' + item?.PAYMENT_PATTERN?.Data : ''"
                    :data-months-reviewed-count="item?.MonthsReviewedCount"
                    >
                    <td class="dont-print"><i class="fa-regular fa-fw fa-folder-closed" aria-hidden="true"></i></td>
                    <td>{{item?.DerogatoryDataIndicator}}</td>
                    <td>{{item?.AccountStatusType}}</td>
                    <td>{{item?.CREDITOR?.Name}}</td>
                    <td>{{item?.AccountType}}</td>
                    <td>{{item?.UnpaidBalanceAmount ? '$'+Number(item?.UnpaidBalanceAmount).toLocaleString() : '-'}}</td>
                    <td>{{item?.TermsDescription}}</td>
                    <td>{{ addSpacesToCamelCase(item?.AccountOwnershipType) }}</td>
                    <td>{{convert3BureauArray(item.CREDIT_REPOSITORY)}}</td>
                </tr>
              </tbody>
            </table>
              </div>  <!-- END table responsive -->

    </template>
<template v-else>
<div>No Tradelines found. </div>
</template>
              </div>  <!-- END col-12 -->
          </div>  <!-- END row -->
      </div> <!--- END container-fluid -->
</div>
</template>
<script setup>
import {addSpacesToCamelCase, convert3BureauArray,summaryTabCount,tradeLinesCreditComments} from '../../composables.js'
</script>
<script>
export default {
  props: ['report'],
  data() {
    return {
      data: this.report?.CREDIT_LIABILITY,
    };
  },
  mounted() {
      this.initializeDataTable();

      const vm = this;

      if (window.matchMedia) {
          window.matchMedia('print').addListener(function(media) {
              if (media.matches) { // Before printing
                  vm.destroyDataTable();
              }else{ // After printing
                  vm.initializeDataTable();
              }
          });
      }

      // Add click event handler to rows
      $(this.$refs.tradeLines_table).on('click', 'tr', (event) => {

          //const rowData = this.dataTable.row(event.currentTarget).data();
          const dataAttributes = event.currentTarget.dataset;

          var newrow_data = '<div class="row ms-5">';
          Object.keys(dataAttributes).forEach(function(atr, index) {
              if(dataAttributes[atr].trim() && dataAttributes[atr].trim()!="NaN" && dataAttributes[atr].trim()!="$NaN"){
                  var atr_friendly = atr.replace(/([A-Z])/g, ' $1');
                  atr_friendly = atr_friendly.charAt(0).toUpperCase() + atr_friendly.slice(1);
                  newrow_data = newrow_data + "<div class='col-2 p-2 text-wrap'><h6 class='fw-bold'>"+atr_friendly +"</h6>" + dataAttributes[atr]+"</div>";
              }
          });

          newrow_data=newrow_data+"</div>";

          let tr = event.target.closest('tr');
          let tri = $(event.target).closest('tr');
          let tdi = tri.find("i");
          let row = this.dataTable.row(tr);

          if (row.child.isShown()) {
              row.child.hide();
              tdi.first().removeClass('fa-folder-open');
              tdi.first().addClass('fa-folder-closed');
          }
          else {
              // Open this row
              row.child(newrow_data);
              row.child.show();
              tdi.first().removeClass('fa-folder-closed');
              tdi.first().addClass('fa-folder-open');
          }

      });
  },
    methods: {
        initializeDataTable() {
            this.dataTable = $(this.$refs.tradeLines_table).DataTable({
                pageLength: 25,
            });
        },
        destroyDataTable() {
            if (this.dataTable) {
                this.dataTable.destroy();
                this.dataTable = null;
            }
        },
    },
    computed: {
        normalizedData() {
            // Check if this.data is an array, and if not, convert the single object to an array
            return Array.isArray(this.data) ? this.data : [this.data];
        },
    },
    created() {
      this.recordCount = summaryTabCount(this.data);
    },
    beforeDestroy() {
        this.destroyDataTable();
    },

};
</script>
