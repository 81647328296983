<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-md-6 pr-2 pr-md-3">
                <!--      &lt;!&ndash; START APPLICANT INFO &ndash;&gt;-->
                <!--      <h4>Applicant Information</h4>-->

                <!--      <dl>-->
                <!--        <dt>Name</dt>-->
                <!--        <dd>-->
                <!--          {{report.CREDIT_FILE.BORROWER?.FirstName ? report.CREDIT_FILE.BORROWER?.FirstName : 'N/A - not in report' }} {{report.CREDIT_FILE.BORROWER?.LastName ? report.CREDIT_FILE.BORROWER?.LastName : 'N/A - not in report' }}-->
                <!--        </dd>-->
                <!--        <dt>DOB</dt>-->
                <!--        <dd>{{report.CREDIT_FILE.BORROWER?.BirthDate ? report.CREDIT_FILE.BORROWER?.BirthDate : 'N/A - not in report' }}</dd>-->
                <!--        <dt>SSN <span class="text-muted small">hover to reveal</span></dt>-->
                <!--        <dd>-->
                <!--          <div @mouseover="showFullSSN = true" @mouseleave="showFullSSN = false" style="width:100px;">-->
                <!--            <span v-if="!showFullSSN">-->
                <!--              {{ maskedSSN }}-->
                <!--            </span>-->
                <!--            <span v-else>-->
                <!--              {{ report.CREDIT_FILE.BORROWER?.SSN ? formatSSN(report.CREDIT_FILE.BORROWER?.SSN) : 'N/A - not in report' }}-->
                <!--            </span>-->
                <!--          </div>-->
                <!--        </dd>-->
                <!--      </dl>-->
                <!--      &lt;!&ndash; END APPLICANT INFO &ndash;&gt;-->

                <!--      <div class="w-100 pb-2"></div>-->

                <!-- START CREDIT SCORES INFO -->
                <h4>Scores</h4>
                <div>
                    <template v-if="creditScores">

                        <div v-for="score in convertToArrayOfObjects(creditScores)" :key="score.ModelNameType" class="card card-outline card-primary">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col">
                                        <div class="float-start">
                                            <h1 class="fw-bold">{{ score.Value ? score.Value : "N/A" }}</h1>
                                        </div>
                                        <div class="float-start ps-4 w-75">
                                            {{ score.ModelNameType ? addSpacesToCamelCase(score.ModelNameType) : "N/A" }} {{ addSpacesToCamelCase(score.ModelNameTypeOtherDescription) }}
                                            <br>
                                            {{ score.CreditRepositorySourceType ? score.CreditRepositorySourceType : "N/A" }}

                                            <div v-if="score && score.ExclusionReasonType">
                                                <b>{{ addSpacesToCamelCase(score.ExclusionReasonType) }}</b>
                                            </div>
                                            <!--Date: {{ score.Date ? score.Date : "N/A" }}-->
                                        </div>
                                    </div>
                                </div>

                                <template v-if="Array.isArray(score.FACTOR)">
                                    <div class="w-100 pb-2"></div>
                                    <div class="text-muted">Factors</div>
                                    <div class="row">
                                        <template v-for="factor in score.FACTOR">
                                            <div class="col-sm">
                                                <span class="fw-bold h6">{{ factor.Code }}</span>
                                                <p class="small">{{ factor.Text }}</p>
                                            </div>
                                        </template>
                                    </div>
                                </template>
                                <div v-else>
                                    <!--  NO FACTORS-->
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="mb-3">No scores returned with report.</div>
                    </template>
                </div>

                <!-- END CREDIT SCORES INFO -->

                <div class="w-100 pb-2"></div>

                <!-- START CREDIT ALERTS INFO -->
                <h4>Credit Alerts</h4>
                <template v-if="createMergedCreditAlerts(alertMessageObj) !== false">
                    <div v-for="(item, index) in createMergedCreditAlerts(alertMessageObj)" :key="index" class="callout callout-danger">
                        <b>{{ item.CategoryType ? addSpacesToCamelCase(item.CategoryType) : "" }}</b> (Code: {{ item.Code ? item.Code : "N/A"}}) / {{ item.Type? item.Type : "" }}
                        {{ item.TypeOtherDescription ? addSpacesToCamelCase(item.TypeOtherDescription) : ""}}<br>
                        {{ item.Text ? item.Text : ""}}
                    </div>
                </template>

                <template v-else>
                    <div class="mb-3">No alerts returned with report.</div>
                </template>
                <!-- END CREDIT ALERTS INFO -->

                <div class="w-100 pb-2"></div>

                <!-- START CREDIT COMMENTS INFO -->
                <h4>Credit Comments</h4>
                <template v-if="createMergedCreditComments()">
                    <div class="callout callout-warning" v-for="item in createMergedCreditComments()">
                        <template v-if="item.ReportedDate">
                            {{ item.ReportedDate }} -
                        </template>
                        <b>{{ addSpacesToCamelCase(item.Type) }}</b> ({{ item.SourceType }})<br>
                        {{ item.Text }} {{ item.BureauRemarks }} {{ item.Code }}
                    </div>
                </template>
                <template v-else>
                    <div class="mb-3">No comments returned with report.</div>
                </template>
                <!-- END CREDIT COMMENTS INFO -->

                <div class="w-100 pb-2"></div>
                <!-- START NAME VARIATIONS INFO -->

                <h4>Name Variations</h4>

                <template v-if="createMergedNameVariations(nameVariationObj)">
                    <div class="row mb-3">
                        <template v-if="bureau.length > 1">
                            <div class="col-5 col-sm-3 dont-print">
                                <div class="nav flex-column nav-tabs h-100" id="nameVariation-tabs-tab" role="tablist" aria-orientation="vertical">
                                    <a v-for="(employers, creditRepositorySource, index) in createMergedNameVariations(nameVariationObj)" :key="index" :class="{'nav-link': true, 'active': index === 0}" :id="'nameVariation-tabs-' + index + '-tab'" data-bs-toggle="pill" :href="'#nameVariation-tabs-' + index" role="tab" :aria-controls="'nameVariation-tabs-' + index"
                                       :aria-selected="index === 0 ? 'true' : 'false'">
                                        {{ creditRepositorySource }}
                                    </a>
                                </div>
                            </div>
                        </template>
                        <div class="col">
                            <div class="tab-content" id="nameVariation-tabs-tabContent">
                                <div v-for="(nameVariations,creditRepositorySourceType,  index) in createMergedNameVariations(nameVariationObj)" :key="index" :class="{'tab-pane': true, 'fade': true, 'active': index === 0, 'show': index === 0, 'always-print': true}"
                                     :id="'nameVariation-tabs-' + index" role="tabpanel" :aria-labelledby="'nameVariation-tabs-' + index+'-tab'">

                                    <div class="only-print">{{ creditRepositorySource }}</div>

                                    <table id="nameVariation_table" ref="nameVariation_table" class="table table-bordered table-striped">
                                        <thead>
                                        <tr>
                                            <th>First Name</th>
                                            <th>Middle Name</th>
                                            <th>Last Name</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="nameVariation in nameVariations">
                                            <td>{{ nameVariation.FirstName ?nameVariation.FirstName : "N/A"}}</td>
                                            <td>{{ nameVariation.MiddleName ? nameVariation.MiddleName : "N/A" }}</td>
                                            <td>{{ nameVariation.LastName ? nameVariation.LastName : "N/A"}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="mb-3">No name variations returned with report.</div>
                </template>
                <!-- END NAME VARIATIONS INFO -->

                <div class="w-100 pb-2"></div>
            </div>
            <div class="col-12 col-md-6">

                <div class="dont-print">

                    <h4>Decisioning</h4>

                    <template v-if="decisioningRecordCount !== 0">
                        <table id="decisioning_table" ref="decisioning_table" class="table table-bordered table-striped">
                            <thead>
                            <tr>
                                <th>Criteria Set</th>
                                <th>Potential Offers</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in decisioning_json"
                                :key="item.criteria_id"
                            >
                                <td>
                                    <div>
                                        {{ item.criteria_set_name }}
                                    </div>
                                    <div class="text-capitalize">
                                        <div class="badge rounded-pill"
                                             :class="{'text-bg-success': item.result === 'pass', 'text-bg-danger': item.result !== 'pass'}">

                                            {{ item.result === 'pass' ? 'Qualified' : 'Not Qualified' }}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <ul class="list-group">
                                        <li v-for="offer in item.offers"
                                            :key="offer.id"
                                            class="list-group-item"
                                            :class="{'list-group-item-success': item.result === 'pass', 'list-group-item-danger': item.result !== 'pass'}"
                                        >
                                            {{ offer.name }} <span class="small">{{ offer.tracking_id }}</span>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </template>
                    <template v-else>
                        <div class="mb-3">Decisioning was not included with the Scenario that was used to process this report.</div>

                    </template>
                </div>

                <div class="w-100 pb-2"></div>
                <!-- START ADDRESS INFO -->

                <h4>Address History</h4>

                <template v-if="residence">
                    <div class="row mb-3">
                        <template v-if="bureau.length > 1">
                            <div class="col-5 col-sm-3 dont-print">
                                <div class="nav flex-column nav-tabs h-100" id="address-tabs-tab" role="tablist" aria-orientation="vertical">
                                    <a v-for="(addresses, creditRepositorySource, index) in createMergedAddresses()" :key="index" :class="{'nav-link': true, 'active': index === 0}" :id="'address-tabs-' + index + '-tab'" data-bs-toggle="tab" :href="'#address-tabs-' + index" role="tab" :aria-controls="'address-tabs-' + index"
                                       :aria-selected="index === 0 ? 'true' : 'false'">
                                        {{ creditRepositorySource }}
                                    </a>
                                </div>
                            </div>
                        </template>
                        <div class="col">
                            <div class="tab-content" id="address-tabs-tabContent">
                                <div v-for="(addresses, creditRepositorySource, index) in createMergedAddresses()" :key="index" :class="{'tab-pane': true, 'fade': true, 'active': index === 0, 'show': index === 0, 'always-print': true}" :id="'address-tabs-' + index" role="tabpanel"
                                     :aria-labelledby="'address-tabs-' + index+'-tab'">

                                    <div class="only-print">{{ creditRepositorySource }}</div>

                                    <table id="address_history_table" ref="address_history_table" class="table table-bordered table-striped">
                                        <thead>
                                        <tr>
                                            <th>Type</th>
                                            <th>Address</th>
                                            <th>Date Reported</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(address) in addresses" :key="address.id">
                                            <td>{{ address.addressType }}</td>
                                            <td>{{ address.streetAddress }}</td>
                                            <td>{{ address.dateReported }}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="mb-3">No address information returned with report.</div>
                    <div class="w-100 pb-2"></div>
                </template>

                <!-- END ADDRESS INFO -->

                <!-- START EMPLOYMENT INFO -->

                <h4>Employment History</h4>

                <template v-if="createMergedEmployments()">
                    <div class="row mb-3">
                        <template v-if="bureau.length > 1">
                            <div class="col-5 col-sm-3 dont-print">
                                <div class="nav flex-column nav-tabs h-100" id="employment-tabs-tab" role="tablist" aria-orientation="vertical">
                                    <a v-for="(employers, creditRepositorySource, index) in createMergedEmployments()" :key="index" :class="{'nav-link': true, 'active': index === 0}" :id="'employment-tabs-' + index + '-tab'" data-bs-toggle="pill" :href="'#employment-tabs-' + index" role="tab" :aria-controls="'employment-tabs-' + index"
                                       :aria-selected="index === 0 ? 'true' : 'false'">
                                        {{ creditRepositorySource }}
                                    </a>
                                </div>
                            </div>
                        </template>
                        <div class="col">
                            <div class="tab-content" id="employment-tabs-tabContent">
                                <div v-for="(employers, creditRepositorySource, index) in createMergedEmployments()" :key="index" :class="{'tab-pane': true, 'fade': true, 'active': index === 0, 'show': index === 0, 'always-print': true}" :id="'employment-tabs-' + index" role="tabpanel"
                                     :aria-labelledby="'employment-tabs-' + index+'-tab'">

                                    <div class="only-print">{{ creditRepositorySource }}</div>

                                    <table id="employment_table" ref="employment_table" class="table table-bordered table-striped">
                                        <thead>
                                        <tr>
                                            <th>Employer</th>
                                            <th>Start Date</th>
                                            <th>Current</th>
                                            <th>Reported Date</th>
                                            <th>Position Description</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="employer in employers" :key="employer.employmentIdentifier">
                                            <td>{{ employer.employerName }}</td>
                                            <td>{{ employer.CurrentEmploymentStartDate || employer.PreviousEmploymentStartDate }}</td>
                                            <td>{{ employer.EmploymentCurrentIndicator }}</td>
                                            <td>{{ employer.EmploymentReportedDate }}</td>
                                            <td>{{ employer.EmploymentPositionDescription }}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="mb-3">No employment information returned with report.</div>
                </template>

                <!-- END EMPLOYMENT INFO -->

            </div>
        </div>
    </div>
</template>
<script setup>
import {
    addSpacesToCamelCase, summaryTabCount
} from '../../composables.js'
</script>
<script>
export default {
    props: ["report", "bureau", "decisioning"],

    data() {
        return {
            residence: this.report.CREDIT_FILE,
            employer: this.report.CREDIT_FILE.BORROWER?.EMPLOYER,
            creditScores: this.bureau.length > 1 ? this.report.CREDIT_SCORE : this.report.CREDIT_SCORE,
            CreditRepositorySourceType: this.report.CREDIT_FILE.CreditRepositorySourceType ? this.report.CREDIT_FILE.CreditRepositorySourceType : "N/A",
            showFullSSN: false,
            creditAlerts: [],
            nameVariationObj: this.report.CREDIT_FILE,
            creditFileObj: this.report.CREDIT_FILE,
            alertMessageObj: this.bureau.length > 1 ? this.report.CREDIT_FILE : this.report?.CREDIT_FILE,
            creditCommentsObj: this.report?.CREDIT_COMMENT,
            ourVar: null,
            addressAssociations: {},
            employerAssociations: {},
            creditVar: {},
            decisioning_json: JSON.parse(this.decisioning) || [],
        };
    },

    computed: {
        hasFactorData() {
            return this.dataWithFactor.length > 0;
        },

        dataWithFactor() {
            return this.creditScores.filter((item) => Array.isArray(item.FACTOR));
        },

        inquires_count() {
            return this.report.hasOwnProperty("CREDIT_SCORE");
        },

        maskedSSN() {
            if (this.report.CREDIT_FILE.BORROWER?.SSN) {
                const ssnString = String(this.report.CREDIT_FILE.BORROWER?.SSN);
                return ssnString.replace(/\d/g, "*");
            }
            return "";
        },
    },
    created() {
        this.decisioningRecordCount = summaryTabCount(this.decisioning_json);
    },

    methods: {
        createMergedAddresses() {
            for (const item of this.normalizedData(this.report.CREDIT_FILE)) {
                const creditRepositorySourceType = item.CreditRepositorySourceType;
                const residences = this.normalizedData(item.BORROWER.RESIDENCE);
                // Check if the CreditRepositorySourceType already exists in the addressAssociations object
                if (!this.addressAssociations[creditRepositorySourceType]) {
                    // If not, initialize it as an empty array
                    this.addressAssociations[creditRepositorySourceType] = [];
                }

                // Loop through the RESIDENCE array to get each StreetAddress
                for (const residence of residences) {
                    const streetAddress = residence.StreetAddress + " " + residence.City + ", " + residence.State + " " + residence.PostalCode;
                    const addressType = residence.BorrowerResidencyType;
                    const dateReported = residence.DateReported;

                    // Check if the current StreetAddress is not already in the array
                    if (!this.addressAssociations[creditRepositorySourceType].some((obj) => obj.streetAddress === streetAddress)) {
                        // If not, add the current StreetAddress to the array
                        this.addressAssociations[creditRepositorySourceType].push({
                            streetAddress: streetAddress,
                            addressType: addressType,
                            dateReported: dateReported,
                        });
                    }
                }
            }
            return this.addressAssociations;
        },

        createMergedEmployments() {
            for (const item of this.normalizedData(this.report.CREDIT_FILE)) {
                const creditRepositorySourceType = item.CreditRepositorySourceType;
                const employments = this.normalizedData(item.BORROWER?.EMPLOYER);

                // Check if the creditRepositorySourceEmployment already exists
                if (!this.employerAssociations[creditRepositorySourceType]) {
                    // If not, initialize it as an empty array
                    this.employerAssociations[creditRepositorySourceType] = [];
                }

                // employments at times can be an empty obj
                if (Array.isArray(employments) && employments.length > 0) {
                    // Loop through the employments array to get each employer
                    for (const employment of employments) {
                        const employerName = employment.Name;
                        const CurrentEmploymentStartDate = employment.CurrentEmploymentStartDate;
                        const EmploymentCurrentIndicator = employment.EmploymentCurrentIndicator;
                        const PreviousEmploymentStartDate = employment.PreviousEmploymentStartDate;
                        const EmploymentReportedDate = employment.EmploymentReportedDate;
                        const EmploymentPositionDescription = employment.EmploymentPositionDescription;
                        const employmentIdentifier = `${employerName}_${EmploymentReportedDate}_${EmploymentPositionDescription}_${EmploymentCurrentIndicator}`;

                        // Check if the current employment identifier is not already in the array
                        if (!this.employerAssociations[creditRepositorySourceType].some((obj) => obj.employmentIdentifier === employmentIdentifier)) {
                            // If not, add the current employer to the array
                            this.employerAssociations[creditRepositorySourceType].push({
                                employerName: employerName,
                                CurrentEmploymentStartDate: CurrentEmploymentStartDate,
                                EmploymentCurrentIndicator: EmploymentCurrentIndicator,
                                PreviousEmploymentStartDate: PreviousEmploymentStartDate,
                                EmploymentReportedDate: EmploymentReportedDate,
                                EmploymentPositionDescription: EmploymentPositionDescription,
                                employmentIdentifier, // Add the unique identifier to the object
                            });
                        }
                    }
                }
            }

            // MAKE SURE THIS STAYS COMMENTED, IT CAUSES AN INFINITE LOOP
            // // Remove empty arrays from the "this.employerAssociations" object
            // for (const creditRepository in this.employerAssociations) {
            //   if (Array.isArray(this.employerAssociations[creditRepository]) && this.employerAssociations[creditRepository].length === 0) {
            //     delete this.employerAssociations[creditRepository];
            //   }
            // }

            if (this.isObjectEmpty(this.employerAssociations)) {
                return null;
            } else {
                return this.employerAssociations;
            }
        },

        createMergedNameVariations() {
            this.nameVariationAssociations = {};
            // Single report
            if (this.bureau.length === undefined) {
                let creditRepositorySourceType = null;
                // Check if this.nameVariationAssociations[creditRepositorySourceType] exists
                if (!this.nameVariationAssociations[creditRepositorySourceType]) {
                    // If not, initialize it as an empty array
                    this.nameVariationAssociations[creditRepositorySourceType] = [];
                }
                const obj = this.normalizedData(this.report.CREDIT_FILE.BORROWER?.ALIAS);
                // check if the obj is empty
                if (obj === false) {
                    return null;
                } else {
                    this.nameVariationAssociations[creditRepositorySourceType].push(...obj);
                    return this.nameVariationAssociations;
                }
            }

            //Merged
            // if (this.report.CREDIT_FILE && this.report.CREDIT_FILE.BORROWER && this.report.CREDIT_FILE.BORROWER.hasOwnProperty('ALIAS')) {

            for (const item of this.normalizedData(this.report.CREDIT_FILE)) {
                const creditRepositorySourceType = item.CreditRepositorySourceType;
                const nameVariations = this.normalizedData(item.BORROWER?.ALIAS);

                // In the event a merged report has no BORROWER.ALIAS
                if (nameVariations === false) {
                    return null;
                }

                if (!this.nameVariationAssociations[creditRepositorySourceType]) {
                    // If not, initialize it as an empty array
                    this.nameVariationAssociations[creditRepositorySourceType] = [];
                }

                for (const nameVariation of nameVariations) {
                    const FirstName = nameVariation.FirstName;
                    const MiddleName = nameVariation.MiddleName;
                    const LastName = nameVariation.LastName;
                    const UnparsedName = nameVariation.UnparsedName;
                    this.nameVariationAssociations[creditRepositorySourceType].push({
                        FirstName: FirstName,
                        MiddleName: MiddleName,
                        LastName: LastName,
                        UnparsedName: UnparsedName,
                    });
                }
            }
            return this.nameVariationAssociations;
        },

        createMergedCreditAlerts() {
            this.creditAlertsAssociations = [];

            // Check if this.alertMessageObj is not an array
            if (!Array.isArray(this.alertMessageObj)) {
                // If it's not an array, convert it to an array containing the original object
                this.alertMessageObj = [this.alertMessageObj];
            }


            // Check if any item in this.alertMessageObj has the property "ALERT_MESSAGE"
            // The code will continue execution if the object has at least one item with "ALERT_MESSAGE" property
            // This because single credit reports don't have the ALERT_MESSAGE element
            if (this.alertMessageObj.some((item) => item.hasOwnProperty("ALERT_MESSAGE")) === false) {

                return false;

            }

            for (const item of this.alertMessageObj) {
                const alertMessages = this.normalizedData(item.ALERT_MESSAGE);
                if (!Array.isArray(alertMessages)) {
                    continue;
                }

                for (const alertMessage of alertMessages) {
                    const CategoryType = alertMessage.CategoryType;
                    const Type = alertMessage.Type;
                    const TypeOtherDescription = alertMessage.TypeOtherDescription;
                    const Code = alertMessage.Code;
                    const Text = alertMessage.Text;
                    const alertMessageIdentifier = `${Text}_${TypeOtherDescription}`;

                    // Check if the current alertMessage identifier is not already in the array
                    if (!this.creditAlertsAssociations.some((obj) => obj.alertMessageIdentifier === alertMessageIdentifier)) {
                        // If not, add the current alertMessage to the array
                        this.creditAlertsAssociations.push({
                            CategoryType: alertMessage.CategoryType,
                            Type: alertMessage.Type,
                            TypeOtherDescription: alertMessage.TypeOtherDescription,
                            Code: alertMessage.Code,
                            Text: alertMessage.Text,
                        });
                    }
                }
            }
            return this.creditAlertsAssociations;
        },

        createMergedCreditComments() {
            this.creditCommentsAssociations = [];

            // There are two element for CREDIT_COMMENT and CREDIT_FILE->CREDIT_COMMENT
            // 1. Look for CREDIT_COMMENT object
            // 2. Look for CREDIT_FILE->CREDIT_COMMENT
            // 3. Then append the two, which have different attributes

            // Check if this.creditCommentsAssociations is not an array
            if (!Array.isArray(this.creditCommentsAssociations)) {
                // If it's not an array, convert it to an array containing the original object
                this.creditCommentsObj = [this.creditCommentsObj];
            }

            if (Array.isArray(this.creditFileObj)) {
                if (this.creditFileObj.some(item => item.hasOwnProperty("CREDIT_COMMENT"))) {
                    this.creditVar = this.creditFileObj.find(item => item.hasOwnProperty("CREDIT_COMMENT"));
                } else {
                    return this.creditCommentsObj;
                }
            } else {
                if (this.creditFileObj.hasOwnProperty("CREDIT_COMMENT")) {
                    this.creditVar = this.creditFileObj.CREDIT_COMMENT; // or this.creditFileObj["CREDIT_COMMENT"]
                } else {
                    // Handle the case when this.creditFileObj is not an array. In this case, return an empty array.
                    this.creditCommentsObj = null;
                }
            }

            if (Array.isArray(this.creditVar)) {
                // It is an array, proceed with extracting CREDIT_COMMENT arrays
                const newArray = this.creditVar.map((item) => {
                    return {
                        ...item,
                    };
                });
                return newArray;
            } else if (typeof this.creditVar === 'object') {
                // It is an object, extract CREDIT_COMMENT array directly
                const creditCommentsArray = this.creditVar.CREDIT_COMMENT ? this.creditVar.CREDIT_COMMENT : {};

                // It seem to be the easiest way combine this obj or arr is just to sort them by length
                const creditCommentsObj_length = this.creditCommentsObj?.length ?? 0;
                const creditCommentsArray_length = creditCommentsArray.length ?? 0;

                if (creditCommentsObj_length > 0 && creditCommentsArray_length == 0) {
                    return this.creditCommentsObj;
                } else if (creditCommentsObj_length == 0 && creditCommentsArray_length > 0) {
                    return creditCommentsArray;
                } else if (creditCommentsObj_length > 0 && creditCommentsArray_length > 0){
                    const combinedObj = creditCommentsArray.concat(this.creditCommentsObj)
                    return combinedObj;
                }

            }
        },

        normalizedData(ourVar) {
            // Check if this.data is an array, and if not, convert the single object to an array
            if (ourVar) {
                return Array.isArray(ourVar) ? ourVar : [ourVar];
            } else {
                return false;
            }
        },

        convertToArrayOfObjects(ourVar) {
            if (!Array.isArray(ourVar)) {
                // If the input is not an array, create a new array with a single object
                return [{
                    ...ourVar,
                }, ];
            }
            // If the input is already an array, but not an array of objects,
            // wrap each element in a new object and return the updated array
            return ourVar.map((item) =>
                typeof item === "object" && item !== null && !Array.isArray(item) ? {
                        ...item,
                    } :
                    item
            );
        },

        isObjectEmpty(obj) {
            return Object.keys(obj).length === 0;
        },
    },
};
</script>
