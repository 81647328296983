<template>
    <div>
        <form @submit.prevent="createCriteriaSet">
    <div class="card-body">
        <!-- Criteria Set Form -->

            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label for="name" required>Criteria Set Name</label>
                        <input type="text" id="name" class="form-control" v-model="criteriaSet.name" required>
                    </div>
                </div>
            </div>

            <!-- Criteria Configs -->
            <label for="criteria" required>Criteria</label>

            <div v-for="(config, index) in criteriaConfigs" :key="index">
                <div class="row mb-3">
                    <div class="col-md-7">
                        <div class="form-group">
                            <v-select v-model="config.parameter"
                                      :options="attributes"
                                      label="name"
                                      :reduce="attribute => attribute.slug"
                                      placeholder="Select an option or type to search"
                                      :clearable="false"
                                      required>
                            </v-select>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <select class="form-select" v-model="config.rule" required>
                                <option value="" disabled>Select a rule</option>
                                <option value="==">Equals</option>
                                <option value="!=">Does Not Equal</option>
                                <option value=">">Greater Than</option>
                                <option value="<">Less Than</option>
                                <option value=">=">Greater Than or Equal To</option>
                                <option value="<=">Less Than or Equal To</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <div class="form-group">
                            <input type="number" class="form-control" v-model="config.value" required>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <button type="button" class="btn btn-danger btn-block" @click="removeCriteriaConfig(index)">
                            <i class="fas fa-trash-alt"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <button type="button" class="btn btn-sm btn-secondary" @click="addCriteriaConfig">Add Additional Criteria</button>
                </div>
            </div>

    </div>
    <div class="card-footer">
        <div class="row">
            <div class="col">
                <button type="submit" class="btn btn-primary">Save Criteria Set</button>
            </div>
        </div>
    </div>
        </form>
    </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
    components: { vSelect },
    data() {
        return {
            criteriaSet: {
                name: ''
            },
            criteriaConfigs: [
                { parameter: '', rule: '', value: '' } // Show one row by default
            ],
            attributes: []
        };
    },
    mounted() {
        this.fetchAttributes();
    },
    methods: {
        createCriteriaSet() {
            // Make an API request to create the CriteriaSet using this.criteriaSet
            // After successful creation, proceed with creating the CriteriaConfigs
            this.createCriteriaConfigs();
        },
        addCriteriaConfig() {
            this.criteriaConfigs.push({
                parameter: '',
                rule: '',
                value: ''
            });
        },
        removeCriteriaConfig(index) {
            this.criteriaConfigs.splice(index, 1);
        },
        createCriteriaConfigs() {
            // Make an API request to create the CriteriaSet using this.criteriaSet
            axios.post('/criteria', { criteria_set: this.criteriaSet, criteria_configs: this.criteriaConfigs })
                .then(response => {
                    // Handle the response here, if needed
                    window.location.href = '/criteria';

                    //console.log('Criteria Set created successfully');
                })
                .catch(error => {
                    // Handle any errors here
                    //console.error('Error creating Criteria Set and Configurations:', error);
                });
        },
        fetchAttributes() {
            axios.get('/get-attributes-for-user')
                .then(response => {
                    this.attributes = response.data;
                })
                .catch(error => {
                   // console.error('Error fetching attributes:', error);
                });
        },
    }
};
</script>
