// mouse.js
import {
  ref,
  onMounted,
  onUnmounted
} from 'vue'


export function convert3BureauArray(creditRepository) {
  if (Array.isArray(creditRepository)) {
    // Map the SourceType values and join them into a single string
    return creditRepository.map(repo => repo.SourceType).join(', ');
  }
  return creditRepository.SourceType;
}

export function convert3BureauPublicRecords(creditRepository) {
  if (Array.isArray(creditRepository)) {
    // Map the SourceType values and join them into a single string
    const resultString = creditRepository.map(item => `${item.SourceType} | ${item.SubscriberCode}`).join(', ');
    return resultString;
  }
  const resultString = creditRepository.SourceType + " | " + creditRepository.SubscriberCode;
  return resultString;
}

export function summaryTabCount(ourVar) {
  if (Array.isArray(ourVar)) {
    return ourVar.length;
  }
  if (typeof(ourVar) == "undefined") {
    return 0
  } else {
    return 1
  }
}

export function addSpacesToCamelCase(inputString) {
    if (!inputString) {
        return ''; // Return an empty string if inputString is undefined or empty
    }

    let result = inputString.replace(/([a-z])([A-Z])/g, '$1 $2');
    result = result.replace(/([A-Z]{2,})([A-Z][a-z])/g, '$1 $2');
    result = result.replace(/([a-z])([A-Z]{2,})/g, '$1 $2');
    return result.trim();
}

export function tradeLinesCreditComments(input) {
  if (Array.isArray(input) && input.length > 0) {
    if (input.length >= 2) {
      // If the array has more than 2 elements, join the Text properties
      const joinedText = input.map(obj => obj.Text).join(' | ');
      return joinedText;
    } else {
      const [{Text: text}] = input;
      return text;
    }
  } else if (typeof input === 'object' && input !== null && 'Text' in input) {
    return input.Text;
  } else {
    return " ";
  }
}

export function convertTransUnion(inputString){
    let updatedString = inputString.replace("transunion", "TransUnion");
    return updatedString.charAt(0).toUpperCase() + updatedString.slice(1);
}
