<template>
<div class="page-break">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">

    <div class="only-print mt-4"><h4>Public Records</h4></div>
  <template v-if="recordCount !== 0">

        <div class="table-responsive">
          <table id="publicRecords_table" ref="publicRecords_table" class="table table-striped border text-nowrap">
            <thead>
              <tr>
                  <th class="no-sort"></th>
                  <th>Date Filed</th>
                  <th>Type</th>
                  <th>Court Name</th>
                  <th>Obligation Amount</th>
                  <th>Ownership Type</th>
                  <th>Docket Identifier</th>
                  <th>Consumer Dispute</th>
              </tr>
            </thead>
            <tbody>
                <tr class="pointer" v-for="item in normalizedData"
                    :key="item.id"
                    :data-bankruptcy-liabilities-amount="item?.BankruptcyLiabilitiesAmount || ''"
                    :data-bankruptcy-assets-amount="item?.BankruptcyAssetsAmount || ''"
                    :data-disposition-date="item?.DispositionDate || ''"
                    :data-disposition-type="item?.DispositionType || ''"
                    :data-plaintiff-name="item?.PlaintiffNamee || ''"
                    :data-date-settled="item?.SettledDate || ''"
                    :data-bankruptcy-type="item?.BankruptcyType || ''"
                    :data-credit-repository="convert3BureauPublicRecords(item?.CREDIT_REPOSITORY)">

                    <td><i class="fa-regular fa-fw fa-folder-closed" aria-hidden="true"></i></td>
                    <td>{{item?.FiledDate || '' }}</td>
                    <td>{{addSpacesToCamelCase(item?.Type)}}</td>
                    <td>{{item?.CourtName}}</td>
                    <td>{{item?.LegalObligationAmount || '-'}}</td>
                    <td>{{item?.AccountOwnershipType}}</td>
                    <td>{{item?.DocketIdentifier}}</td>
                    <td>{{item?.ConsumerDisputeIndicator}}</td>
                </tr>
            </tbody>
          </table>
        </div>

</template>
<template v-else>
  <div>No Public Records found.</div>
</template>
            </div>
        </div>
    </div>
</div>
</template>

<script setup>
import {addSpacesToCamelCase, convert3BureauPublicRecords,summaryTabCount} from '../../composables.js'

</script>
<script>


export default {
  props: [
    'report'
  ],
    data() {
        return {
            data: this.report?.CREDIT_PUBLIC_RECORD
        };
    },
  mounted() {
      this.initializeDataTable();

      const vm = this;

      if (window.matchMedia) {
          window.matchMedia('print').addListener(function(media) {
              if (media.matches) { // Before printing
                  vm.destroyDataTable();
              }else{ // After printing
                  vm.initializeDataTable();
              }
          });
      }

      // Add click event handler to rows
      $(this.$refs.publicRecords_table).on('click', 'tr', (event) => {

          //const rowData = this.dataTable.row(event.currentTarget).data();
          const dataAttributes = event.currentTarget.dataset;

          var newrow_data = '<div class="row ms-5">';
          Object.keys(dataAttributes).forEach(function(atr, index) {

              if(dataAttributes[atr]){

                  var atr_friendly = atr.replace(/([A-Z])/g, ' $1');
                  atr_friendly = atr_friendly.charAt(0).toUpperCase() + atr_friendly.slice(1);

                  newrow_data = newrow_data + "<div class='col'><h6 class='fw-bold'>"+atr_friendly +"</h6>" + dataAttributes[atr]+"</div>";
              }

          });

          newrow_data=newrow_data+"</div>";

          let tr = event.target.closest('tr');
          let tri = $(event.target).closest('tr');
          let tdi = tri.find("i");
          let row = this.dataTable.row(tr);

          if (row.child.isShown()) {
              row.child.hide();
              tdi.first().removeClass('fa-folder-open');
              tdi.first().addClass('fa-folder-closed');
          }
          else {
              // Open this row
              row.child(newrow_data);
              row.child.show();
              tdi.first().removeClass('fa-folder-closed');
              tdi.first().addClass('fa-folder-open');
          }

      });
  },
    methods: {
        initializeDataTable() {
            this.dataTable = $(this.$refs.publicRecords_table).DataTable({
                "pageLength": 25
            });
        },
        destroyDataTable() {
            if (this.dataTable) {
                this.dataTable.destroy();
                this.dataTable = null;
            }
        },
    },
    computed: {
        normalizedData() {
            // Check if this.data is an array, and if not, convert the single object to an array
            return Array.isArray(this.data) ? this.data : [this.data];
        },
    },
    created() {
      this.recordCount = summaryTabCount(this.data);
    },
    beforeDestroy() {
        this.destroyDataTable();
    },
};
</script>
