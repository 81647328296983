<template>
<div>
  <template v-if="recordCount !== 0">
    <div class="container-fluid dont-print">
      <div class="row">
        <div class="col-12 table-responsive-sm">

          <table id="attributes_table" ref="attributes_table" class="table table-striped border text-nowrap">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in normalizedData">
                <td>{{item.AttributeID}}</td>
                <td>{{item.Name}}</td>
                <td>{{item.Value}}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Value</th>
              </tr>
            </tfoot>
          </table>

        </div>
      </div>
    </div>
</template>
<template v-else>
<div>No attributes calculated with report.</div>
</template>
</div>
</template>
<script setup>
import {
    summaryTabCount
} from '../../composables.js'
</script>
<script>
export default {
  props: [
   'report'
 ],
    data() {
        return {
            data: this.report?.CREDIT_SUMMARY['DATA_SET'],
        };
    },
  mounted() {
    $(this.$refs.attributes_table).DataTable({
        "pageLength": 25
        }
    );
  },
    computed: {
        normalizedData() {
            // Check if this.data is an array, and if not, convert the single object to an array
            return Array.isArray(this.data) ? this.data : [this.data];
        },
    },
    created() {
      this.recordCount = summaryTabCount(this.data);
    },
};
</script>
