<template>
    <div>
      <form @submit.prevent="updateCriteriaConfigs">
    <div class="card-body">
        <!-- Criteria Set Form -->
            <!-- Success Banner -->
            <div v-if="updateSuccess" class="alert alert-success mt-3">
                Criteria Set updated successfully.
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label for="name" required>Criteria Set Name</label>
                        <input type="text" id="name" class="form-control" v-model="criteriaSet.name" required>
                    </div>
                </div>
            </div>

            <!-- Criteria Configs -->
            <label for="criteria" required>Criteria</label>

            <div v-for="(config, index) in criteriaConfigs" :key="index">
                <!-- Conditionally apply a border class to the row's container based on to_be_deleted -->
                <div :class="{'border': config.to_be_deleted, 'border-danger': config.to_be_deleted, 'my-2': config.to_be_deleted, 'p-2': config.to_be_deleted}">

                <div v-if="config.to_be_deleted">
                    <strong>Marked for deletion</strong>
                </div>
                <div class="row mb-3">
                    <div class="col-md-7">
                        <div class="form-group">
                            <v-select v-model="config.parameter"
                                      :options="attributes"
                                      label="name"
                                      :reduce="attribute => attribute.slug"
                                      placeholder="Select an option or type to search"
                                      :clearable="false"
                                      required>
                            </v-select>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <select class="form-select" v-model="config.rule" required>
                                <option value="" disabled>Select a rule</option>
                                <option value="==">Equals</option>
                                <option value="!=">Does Not Equal</option>
                                <option value=">">Greater Than</option>
                                <option value="<">Less Than</option>
                                <option value=">=">Greater Than or Equal To</option>
                                <option value="<=">Less Than or Equal To</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <div class="form-group">
                            <input type="number" class="form-control" v-model="config.value" required>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <div>
                            <button v-if="!config.to_be_deleted"
                                    type="button"
                                    class="btn btn-danger btn-block"
                                    @click="removeCriteriaConfig(index)"
                            >
                                <i class="fas fa-trash-alt"></i>
                            </button>
                            <button v-else
                                    type="button"
                                    class="btn btn-danger btn-block"
                                    @click="cancelDeleteCriteriaConfig(index)">
                                <i class="fas fa-undo-alt" style="font-size: 1.5rem; cursor: pointer;"></i>
                            </button>
                        </div>
                    </div>
                </div>
                </div>
            </div>

            <button type="button" class="btn btn-sm btn-secondary" @click="addCriteriaConfig">Add Additional Criteria</button>


    </div>
    <div class="card-footer">
        <div class="row">
            <div class="col">
                <button type="submit" class="btn btn-primary">Save Criteria Set</button>
            </div>
            <div class="col text-end">
                <button type="button" class="btn btn-danger" @click="showDeleteWarning" v-if="criteriaConfigData.slug">
                    <i class="fa-solid fa-trash fa-fw"></i> Delete Criteria Set
                </button>
            </div>
        </div>
    </div>
      </form>
    </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
    components: {
        vSelect
    },
    props: {
        criteriaConfigData: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            attributes: [],
            updateSuccess: false, // Add a new data property to track update success
            criteriaSet: {
                name: this.criteriaConfigData.name,
            },
            criteriaConfigs: this.criteriaConfigData.criteria_configs,
        };
    },
    mounted() {
        this.fetchAttributes();
    },
    methods: {
        // Implement the methods needed for updating the criteria set and configurations

        // For example, you can create a method to submit the updated data to the backend API.
        updateCriteriaSet() {
            // Your logic here to update the criteria set and its configurations using the API.
            // You can access the data through this.criteriaSet for the criteria set details
            // and this.criteriaConfigs for the criteria configurations.
        },

        createCriteriaSet() {
            // Make an API request to create the CriteriaSet using this.criteriaSet
            // After successful creation, proceed with creating the CriteriaConfigs
            this.createCriteriaConfigs();
        },
        addCriteriaConfig() {
            this.criteriaConfigs.push({
                parameter: '',
                rule: '',
                value: ''
            });
        },
        removeCriteriaConfig(index) {
            const config = this.criteriaConfigs[index];

            if (config.slug) {
                // If criteriaConfig.slug is set, mark the config as to_be_deleted
                config.to_be_deleted = true;
            } else {
                // If criteriaConfig.slug is not set, remove the config from the array
                this.criteriaConfigs.splice(index, 1);
            }
        },
        cancelDeleteCriteriaConfig(index) {
            const config = this.criteriaConfigs[index];

            if (config.slug && config.to_be_deleted) {
                // If criteriaConfig.slug is set and to_be_deleted is true, revert to_be_deleted to false
                config.to_be_deleted = false;
            } else {
                // If criteriaConfig.slug is not set or to_be_deleted is already false, do nothing (or handle your desired behavior)
            }
        },
        updateCriteriaConfigs() {
            // Make an API request to create the CriteriaSet using this.criteriaSet

            const criteriaSlug = this.criteriaConfigData.slug;

            axios.post(`/criteria/update/${criteriaSlug}`, { criteria_set: this.criteriaSet, criteria_configs: this.criteriaConfigs })
                .then(response => {
                    this.updateSuccess = true; // Set the update success status to true
                    window.location.href = '/criteria';

                    //console.log('Criteria Set and Configurations updated successfully');
                })
                .catch(error => {
                    //console.error('Error creating Criteria Set and Configurations:', error);
                });
        },
        fetchAttributes() {
            axios.get('/get-attributes-for-user')
                .then(response => {
                    this.attributes = response.data;
                })
                .catch(error => {
                    console.error('Error fetching attributes:', error);
                });
        },
        isSelected(config, attribute) {
            //console.log(config.slug,attribute.slug);
            return config.slug === attribute.slug;
        },
        showDeleteWarning() {
            // Display a confirmation dialog to the user
            const confirmation = window.confirm('Are you sure you want to delete this criteria set?');

            if (confirmation) {
                // The user clicked "OK" in the confirmation dialog, proceed with deletion
                this.deleteCriteria();
                window.location = '/criteria';
            }
            // If the user clicked "Cancel," do nothing and the deletion process will be canceled.
        },

        deleteCriteria() {
            const criteriaSlug = this.criteriaConfigData.slug;

            if (criteriaSlug) {
                // Make an API request to delete the criteria
                axios.delete(`/criteria/delete/${criteriaSlug}`)
                    .then(response => {
                        // Handle the response here, if needed
                        this.updateSuccess = true; // Set the update success status to true
                        //console.log('Criteria Set and Configurations deleted successfully');

                    })
                    .catch(error => {
                        //console.error('Error deleting Criteria Set and Configurations:', error);
                    });
            }
        },

    }
};
</script>
